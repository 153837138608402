<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>
    <!-- Email Settings -->

    <div class="mx-auto mt-3 lg:mt-10 md:mt-5  max-w-6xl" v-if="isLoaded">
      <div>
        <div>
          <div class="font-bold text-22px text-oCharcoal">
            <i class="fas fa-envelope text-22px text-oPurple"></i>
            <span class="pl-3">Email Settings</span>
          </div>
          <p class="break-words title-description mt-1">
            To send email to Riders for various events (Trip invoice, Sign up
            bonus, Referral Bonus) you need to configure email.
          </p>
        </div>
      </div>
      <div :key="`email_${counter}`" class=" mt-3 pt-5 border-t-2">
        <template v-for="(item, itemIndex) in emailSettings">
          <SmartAppInput
            v-if="itemIndex <= 1"
            :key="itemIndex"
            :rowId="`email_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :isEdit="item.isEdit"
            :options="item.options"
            v-model="email[`${item.variable}`]"
            @save="onSave(`${item.variable}`, $event)"
          />
          <SmartAppInput
            v-if="itemIndex > 1 && itemIndex <= 5 && email.email_is_smtp"
            :key="itemIndex"
            :rowId="`email_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :isEdit="item.isEdit"
            :options="item.options"
            v-model="email[`${item.variable}`]"
            @save="onSave(`${item.variable}`, $event)"
          />
          <SmartAppInput
            v-if="itemIndex === 6 && !email.email_is_smtp"
            :key="itemIndex"
            :rowId="`email_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :isEdit="item.isEdit"
            :options="item.options"
            v-model="email[`${item.variable}`]"
            @save="onSave(`${item.variable}`, $event)"
          />
        </template>
      </div>
    </div>
  </section>
</template>
<script>
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import { useEndpoints } from '@/composables'
import { EventBus } from '@/utils'
export default {
  name: 'EmailSettings',
  components: { SmartAppInput },
  data() {
    return {
      isLoaded: false,
      counter: 0,
      email: {
        email_address: null,
        email_is_smtp: null,
        email_password: null,
        email_host: null,
        email_port: null,
        email_encryption: null,
        email_api_key: null,
      },
      emailSettings: [
        {
          type: 'text',
          label: 'Email Address',
          placeholder: `e.g  noreply@otoride.co`,
          description:
            'Enter Email address which will be used as Sender Email address for every email template.',
          variable: 'email_address',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Email Is SMTP',
          description: ` Enter email SMTP `,
          options: [
            { value: true, text: 'Yes' },
            { value: false, text: 'No' },
          ],
          variable: 'email_is_smtp',
          isEdit: false,
        },
        {
          type: 'password',
          label: 'Email Password',
          placeholder: `e.g  1234qwe`,
          description: 'Enter email password',
          variable: 'email_password',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'Email Host',
          placeholder: `e.g ssa.as2.sd`,
          description: 'Enter Email host(e.g: ssa.as2.sd)',
          variable: 'email_host',
          isEdit: false,
        },
        {
          type: 'number',
          label: 'Email Port',
          placeholder: `e.g 2026`,
          description: 'Enter Email Port(e.g: 2026)',
          variable: 'email_port',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Email Encryption',
          description: `Enter Email Encryption`,
          options: [
            { value: 'TLS', text: 'TLS' },
            { value: 'SSL', text: 'SSL' },
            { value: 'None', text: 'None' },
          ],
          variable: 'email_encryption',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'Email API Key',
          placeholder: `e.g: SG.SiY-..............-piwuRPs`,
          description:
            ' Enter SendGrid API key for sending email to Rider(e.g: SG.SiY-..............-piwuRPs) ',
          variable: 'email_api_key',
          isEdit: false,
        },
      ],
    }
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    endpoint() {
      return useEndpoints.settings.email.index(this.orgId)
    },
  },
  async created() {
    this.$http
      .get(this.endpoint)
      .then(({ data }) => {
        const formatted = {}
        for (const k in data) {
          formatted[k] = data[k] === null ? '' : data[k]
        }
        this.email = { ...formatted }
        this.isLoaded = true
      })
      .catch((err) => {
        console.log('emailSettingsErr', err)
      })
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'email') {
        this.emailSettings = this.emailSettings.map((item, itemIndex) => {
          if (id === `email_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
      }
    })
  },
  methods: {
    async onSave(key, val) {
      let toastTitle, toastText

      if (key === 'email_address') {
        toastTitle = `Email Address`
        toastText = `Email Address is updated`
      }
      if (key === 'email_is_smtp') {
        toastTitle = `Email Is SMTP`
        toastText = `Email Is SMTP is updated`
      }
      if (key === 'email_password') {
        toastTitle = `Email Password`
        toastText = `Email Password is updated`
      }
      if (key === 'email_host') {
        toastTitle = `Email Host`
        toastText = `Email Host is updated`
      }
      if (key === 'email_port') {
        toastTitle = `Email Port`
        toastText = `Email Port is updated`
      }
      if (key === 'email_encryption') {
        toastTitle = `Email Encryption`
        toastText = `Email Encryption is updated`
      }
      if (key === 'email_api_key') {
        toastTitle = `Email API Key`
        toastText = `Email API Key is updated`
      }

      let data = new FormData()
      data.append(key, val)

      await this.$http
        .patch(this.endpoint, data)
        .then((res) => {
          console.log(res)
          this.emailSettings = this.emailSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          this.counter++
        })
        .catch((err) => {
          console.log('onSaveError', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: toastTitle,
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.pencil-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.title-description {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #63636c !important;
}
</style>
